<template>
  <div>
    <h3 class="title">日志管理</h3>
    <el-divider></el-divider>
    <el-date-picker
      v-model="date"
      align="right"
      value-format="yyyy-MM-dd"
      type="date"
      placeholder="选择日期"
      @change="getList"
      :picker-options="pickerOptions">
    </el-date-picker>
    <el-button type="danger" plain @click="clearLogs" style="margin-left:10px;">清空日志</el-button>
    <p class="log-text">{{logs}}</p>
  </div>
</template>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        date:null,
        logs:null,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        }
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/logs",{
          date:self.date
        }).then(response => {
          if (response.status == 200) {
            self.logs = response.data;
          } else{
            console.log(response.message);
          }
          self.loading = false;
        }).catch(err => {
          console.log('××××××××××××××');
          self.loading = false;
        });
      },
      clearLogs() {
        self.$remove('admin/logs',{
          date:self.date
        }).then((response) => {
          console.log(response);
          if(response.status == 204){
            self.getList();
            self.$message({
              message: "删除操作完成",
              type: "success",
              duration: 1200
            });
          }else{
            console.log(response);
          }
        }).catch(err => {
          console.log('××××××××××××××××');
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .log-text{
    color: #606266;
    font-size: 16px;
    line-height: 26px;
    padding: 10px 0;
    white-space: pre-line;
    word-break: break-all;
    font-family: arial;
  }
</style>

